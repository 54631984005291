<template>
  <div>
    <a-popover v-model="visible" trigger="click" title="属性字段" placement="left">
      <template #content>
        <div style="max-height: 400px; overflow-y: auto">
          <loaded-device-property-card :properties="[form]" v-if="form" />
        </div>
      </template>
      <a-button type="link" :loading="spinning" @click="handleClick">查看</a-button>
    </a-popover>
  </div>
</template>

<script>
import LoadedDevicePropertyCard from '../../loaded-device/components/loadedDevicePropertyCard';

export default {
  name: 'PropertyPopover',
  props: ['id'],
  components: { LoadedDevicePropertyCard },
//   async mounted() {
//     this.listPropertiesById(this.id);
//   },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      spinning: false,
      visible: false,
      form: null,
    };
  },
  methods: {
    async listPropertiesById(ids) {
      const params = {
        id: ids,
      };
      const headers = {
        'accept-language': this.languageSelect,
      };
      try {
        this.spinning = true;
        const response = await this.$apiManager.property.getPropertiesById(params, headers);
        this.spinning = false;
        this.form = response.data;
      } catch (error) {
        this.spinning = false;
      } finally {
        this.spinning = false;
      }
    },
    async handleClick() {
       await this.listPropertiesById(this.id);
       this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
